import React from "react";
import { graphql } from "gatsby";

import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Wrapper, Button, SolutionsTabNav } from "../components/core";
import { HeroHeader, SolutionsPreviewSection, CTASection } from "../components/blocks";

export default function homePageTemplate(props) {
  const siteMetaData = props.data.site.siteMetadata;
  const siteData = props.data.allSanitySiteConfig.edges[0].node;
  const sharedData = props.data.allSanitySharedSections.edges[0].node;
  const solutionsData = props.data.allSanitySolution.edges;
  const pageData = props.data.allSanityHomePage.edges[0].node;

  let tabItems = solutionsData.map(({ node }) => {
    return {
      title: node.solutionPreview?.solutionTitle,
      iconUrl: node.solutionPreview?.iconImage?.asset?.fluid?.src,
    };
  });

  return (
    <Layout
      locale={props.pageContext.locale}
      siteMetaData={siteMetaData}
      mainNav={siteData.mainNav}
      alternateNav={siteData.alternateNav}>
      <SEO title={pageData.pageTitle?.localized} />
      <HeroHeader
        title={pageData.header?.title?.localized}
        description={pageData.header?.description?.localized}
        coverImageUrl={pageData.header?.coverImage?.asset?.fluid?.src}
        callToActionTitle={pageData.header?.callToAction?.linkTitle?.localized}
        callToActionRoute={pageData.header?.callToAction?.route?.slug?.current}
      />
      <SolutionsPreviewSection
        solutions={pageData.solutionsSection?.solutions ?? []}
        tabItems={tabItems ?? []}
      />
      <CTASection
        title={sharedData.callToActionSection?.title?.localized}
        description={sharedData.callToActionSection?.description?.localized}
        callToActionTitle={sharedData.callToActionSection?.callToAction?.linkTitle?.localized}
        callToActionRoute={sharedData.callToActionSection?.callToAction?.route?.slug?.current}
      />
    </Layout>
  );
}

import * as _ from "./fragments";

export const query = graphql`
  query($locale: String) {
    # Site Meta Data
    site {
      ...SiteMetaData
    }
    # Site
    allSanitySiteConfig {
      edges {
        node {
          ...MainNav
          ...AlternateNav
        }
      }
    }
    # Shared
    allSanitySharedSections {
      edges {
        node {
          ...CallToActionSection
        }
      }
    }
    # Solutions
    # Used for the solutions tab (Clean Onn / Board Onn).
    allSanitySolution(sort: { fields: sortOrder, order: ASC }) {
      edges {
        node {
          solutionPreview {
            solutionTitle
            iconImage {
              ...Image
            }
          }
        }
      }
    }
    # Page
    allSanityHomePage {
      edges {
        node {
          pageTitle {
            localized(locale: $locale)
          }
          header {
            title {
              localized(locale: $locale)
            }
            description {
              localized(locale: $locale)
            }
            callToAction {
              linkTitle {
                localized(locale: $locale)
              }
              route {
                slug {
                  current
                }
              }
            }
            coverImage {
              ...Image
            }
          }
          solutionsSection {
            solutions {
              solutionPreview {
                ...SolutionPreview
              }
            }
          }
        }
      }
    }
  }
`;
